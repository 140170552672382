import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Collapse from 'react-bootstrap/Collapse';

const Center = ({ buttonText, text, phone }) => {
  const [secondOpen, setSecondOpne] = useState(false);
  return (
    <section className="py-2">
      <Container>
        <Card>
          <Card.Header>
            <h2 class="mb-0">
              <button
                className="btn btn-link btn-block text-warning text-left border-0 btn-sm"
                onClick={() => setSecondOpne(!secondOpen)}
                aria-controls="example-collapse"
                aria-expanded={secondOpen}
              >
                {buttonText}
              </button>
            </h2>
          </Card.Header>

          <Collapse in={secondOpen}>
            <div id="example-collapse">
              <Card.Body>
                <p className="text-small">{text}</p>
              </Card.Body>
            </div>
          </Collapse>
        </Card>
      </Container>
    </section>
  );
};

export default Center;
