import React from 'react';
import Container from 'react-bootstrap/Container';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import Center from '../components/Center';

const Licensingcenters = () => {
  return (
    <div id="wrapper">
      <Helmet>
        <title>Centers</title>
      </Helmet>
      <Layout>
        <Container>
          <h5 className="mt-4">License Centers</h5>
          <div
            style={{
              width: '40px',
              height: '2px',
              background: 'green',
              marginBottom: '10px',
            }}
          ></div>
        </Container>
        <Center buttonText="Attock" text="INCHARGE TRAFFIC DISTRICT ATTOCK" />
        <Center
          buttonText="Bahawalnagar"
          text="DSP TRAFFIC OFFICE OPPOSITE DISTRICT JAIL BAHAWALNAGAR"
        />
        <Center
          buttonText="Bahawalpur"
          text="PTCL EXCHANGE, SATELLITE TOWN, BAHAWALPUR"
        />
        <Center buttonText="Bhakkar" text="D.P.O OFFICE BHAKKAR" />
        <Center buttonText="Chakwal" text="DSP TRAFFIC DISTRICT CHAKWAL" />
        <Center
          buttonText="Chiniot"
          text="PINDI BHATAIN ROAD IN FRONT OF POLICE LINE CHINIOT"
        />
        <Center buttonText="Dera Ghazi Khan" text="RAILWAY ROAD DG KHAN" />
        <Center
          buttonText="Faisalabad"
          text="CTO FAISALABAD, NAZAD THANA CIVIL LINE, KUTCHERY BAZAR, FAISALABAD."
        />
        <Center
          buttonText="Gujrat"
          text="PURANA G.T ROAD NEAR AMIN FAN, GUJRAT"
        />
        <Center buttonText="Hafizabad" text="PTCL EXCHANGE HAFIZABAD" />
        <Center
          buttonText="Jhang"
          text="BACK SIDE OF POLICE WELFARE PETROL PUMP NEAR SADAR CHOWK JHANG"
        />
        <Center buttonText="Jhelum" text="DSP TRAFFIC DISTRICT JHELUM" />
        <Center
          buttonText="Kasur"
          text="D.P.O OFFICE COMPLEX, DISTRICT COURT FEROZE PUR ROAD KASUR"
        />
        <Center buttonText="Khanewal" text="D.P.O OFFICE KHANAWAL" />
        <Center buttonText="Khushab" text="D.P.O OFFICE KHUSHAB" />
        <Center buttonText="Layyah" text="D.P.O OFFICE LAYYAH" />
        <Center buttonText="Lodhran" text="D.P.O OFFICE LODHRAN" />
        <Center
          buttonText="Mandi Bahaud Din"
          text="EX-D.P.O OFFICE, PS SADAR M.B. DIN PHALIA ROAD M.B. DIN"
        />
        <Center
          buttonText="Mianwali"
          text="PROVINCIAL HIGHWAYS BUILDING NEAR JAHAAZ CHOWK, MIANWALI"
        />
        <Center buttonText="Muazaffargarh" text="D.P.O OFFICE MUZAFFARGARH" />
        <Center buttonText="Multan" text="POLICE LINES NO.1 MULTAN" />
        <Center buttonText="Nankana Sahib" text="PS SADAR NANKANA SAHIB" />
        <Center
          buttonText="Narowal"
          text="PS SADAR NAROWAL SHAKARGARH ROAD NEAR NEW KACHAHRI NAROWAL"
        />
        <Center
          buttonText="Okara"
          text="DSP/TRAFFIC  OFFICE INSIDE D.P.O OFFICE OKARA"
        />
        <Center buttonText="Pakpattan" text="D.P.O OFFICE, PAKPATTAN" />
      </Layout>
    </div>
  );
};

export default Licensingcenters;
